<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.orderStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="待发货"
                    name="20"
                />
                <el-tab-pane
                    label="仓库中"
                    name="30-1"
                />
                <el-tab-pane
                    label="已发货"
                    name="30-2"
                />
                <el-tab-pane
                    label="已完成"
                    name="100"
                />
                <el-tab-pane
                    label="已取消"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="orderSn"
                    label="订单编号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryConsignee"
                    label="收件人"
                >
                    <el-input
                        v-model="queryFormModel.deliveryConsignee"
                        placeholder="请输入收件人"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryPhone"
                    label="收件人电话"
                >
                    <el-input
                        v-model="queryFormModel.deliveryPhone"
                        placeholder="请输入收件人电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderType"
                    label="订单类型"
                >
                    <dictionaries-picker
                        type="orderType"
                        v-model="queryFormModel.orderType"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentMode"
                    label="付款类型"
                >
                    <dictionaries-picker
                        type="paymentMode"
                        v-model="queryFormModel.paymentMode"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerLevelId"
                    label="会员等级"
                >
                    <el-select
                        v-model="queryFormModel.buyerLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="orderSource"
                    label="订单来源"
                >
                    <dictionaries-picker
                        type="orderSource"
                        v-model="queryFormModel.orderSource"
                    />
                </el-form-item>
                <el-form-item
                    prop="extendFieldInfo"
                    label="扩展信息"
                >
                    <el-input
                        v-model="queryFormModel.extendFieldInfo"
                        placeholder="请输入扩展信息"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderTime"
                    label="下单时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.orderTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportOrderExcel"
                    >
                        订单导出
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportGoodsStatExcel"
                    >
                        商品统计
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-upload2"
                        size="small"
                        @click="onBatchShippingOrder"
                        v-show="queryFormModel.orderStatus == '30-1'"
                    >
                        批量发货
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-upload2"
                        size="small"
                        @click="onBatchStockHandleOrder"
                        v-show="queryFormModel.orderStatus == 20"
                    >
                        批量转仓库处理
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column type="expand">
                    <template slot-scope="order">
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="order.row.orderDetailList"
                            style="width: 100%"
                        >
                            <el-table-column
                                label="商品图片"
                                width="120"
                            >
                                <template slot-scope="scope">
                                    <ImageList
                                        :data="scope.row.specImageUrl"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="specDesc"
                                label="商品规格"
                                min-width="100"
                            />
                            <el-table-column
                                prop="finalPrice"
                                label="商品价格"
                                min-width="50"
                            />
                            <el-table-column
                                prop="quantity"
                                label="数量"
                                min-width="50"
                            />
                            <el-table-column
                                prop="subtotalAmount"
                                label="小计"
                                min-width="50"
                            />
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderSn"
                    label="订单编号"
                    width="150"
                >
                    <template slot-scope="scope">
                        <ImageList
                            class="dp-ib"
                            :data="scope.row.buyerLevelIconUrl"
                            item-width="20px"
                        />
                        {{ scope.row.orderSn }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="下单时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="payableAmount"
                    label="订单金额"
                    width="70"
                >
                    <template
                        slot-scope="scope"
                    >
                        <el-popover
                            trigger="hover"
                        >
                            <el-table :data="scope.row.amountInfoList">
                                <el-table-column
                                    width="150px"
                                    property="label"
                                />
                                <el-table-column
                                    width="100px"
                                    property="value"
                                />
                            </el-table>
                            <span
                                slot="reference"
                                class="tc-blue"
                            >{{ scope.row.payableAmount }}</span>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderSourceName"
                    label="订单来源"
                    width="70"
                />
                <el-table-column
                    prop="orderTypeName"
                    label="订单类型"
                    width="70"
                />
                <el-table-column
                    prop="paymentModeName"
                    label="付款类型"
                    width="70"
                />
                <el-table-column
                    prop="deliveryConsignee"
                    label="收件人"
                    width="90"
                />
                <el-table-column
                    prop="deliveryPhone"
                    label="收件人电话"
                    width="100"
                />
                <el-table-column
                    label="收件人地址"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.deliveryRegionName }} {{ scope.row.deliveryAddress }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderStatusName"
                    label="订单状态"
                    width="70"
                />
                <el-table-column
                    prop="buyerMsg"
                    label="买家留言"
                    min-width="100"
                />
                <el-table-column
                    label="扩展信息"
                    min-width="100"
                >
                    <template
                        slot-scope="scope"
                        v-if="scope.row.extendFieldInfoList && scope.row.extendFieldInfoList.length != 0"
                    >
                        <el-popover
                            trigger="hover"
                        >
                            <el-table :data="scope.row.extendFieldInfoList">
                                <el-table-column
                                    width="150px"
                                    property="label"
                                    label="字段名称"
                                />
                                <el-table-column
                                    width="200px"
                                    property="value"
                                    label="字段值"
                                />
                            </el-table>
                            <span
                                slot="reference"
                                class="tc-blue"
                            >{{ scope.row.extendFieldInfoList[0].label }}:{{ scope.row.extendFieldInfoList[0].value }}</span>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/storeOrder/order/orderDetail/' + scope.row.id)"
                        >
                            查看
                        </el-button>
                        <template v-if="(userData.user.appId === 2 && !scope.row.storeId) || (userData.user.appId === 3 && scope.row.storeId)">
                            <el-button
                                type="text"
                                size="small"
                                @click="shippingOrder(scope.row)"
                                v-if="scope.row.orderStatus === 30 && scope.row.stockHandleStatus === 2 && scope.row.goodsType === 1"
                            >
                                发货
                            </el-button>
                            <el-button
                                type="text"
                                size="small"
                                @click="stockHandleOrder(scope.row.id)"
                                v-if="scope.row.orderStatus === 20 && scope.row.stockHandleStatus === 1 && scope.row.goodsType === 1"
                            >
                                转仓库处理
                            </el-button>
                            <el-button
                                type="text"
                                size="small"
                                @click="completeOrder(scope.row)"
                                v-if="scope.row.orderStatus === 20 && scope.row.goodsType !== 1"
                            >
                                完成订单
                            </el-button>
                            <el-button
                                type="text"
                                size="small"
                                @click="cancelOrder(scope.row)"
                                v-if="scope.row.orderStatus !== 99 && scope.row.isSettlement !== 1"
                            >
                                取消订单
                            </el-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <el-dialog
            title="订单发货"
            center
            width="600px"
            :visible.sync="shippingDialog.isVisible"
            @closed="onShippingDialogCancel"
        >
            <el-form
                ref="shippingForm"
                size="small"
                :model="shippingDialog.formModel"
                :rules="shippingDialog.formRules"
            >
                <el-form-item
                    key="orderSn"
                    prop="orderSn"
                    label="订单编号"
                    label-width="8em"
                >
                    {{ shippingDialog.orderData.orderSn }}
                </el-form-item>
                <el-form-item
                    key="deliveryConsignee"
                    prop="deliveryConsignee"
                    label="收件人"
                    label-width="8em"
                >
                    {{ shippingDialog.orderData.deliveryConsignee }}
                </el-form-item>
                <el-form-item
                    key="deliveryRegion"
                    prop="deliveryRegion"
                    label="收件人地址"
                    label-width="8em"
                >
                    {{ shippingDialog.orderData.deliveryRegionName }} {{ shippingDialog.orderData.deliveryAddress }}
                </el-form-item>
                <el-form-item
                    key="expressId"
                    prop="expressId"
                    label="快递公司"
                    label-width="8em"
                >
                    <el-select
                        v-model="shippingDialog.formModel.expressId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in shopExpressList"
                            :key="item.expressId"
                            :label="item.expressName"
                            :value="item.expressId"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    key="expressName"
                    prop="expressName"
                    label="快递公司名称"
                    label-width="8em"
                    v-if="shippingDialog.formModel.expressId === -1"
                >
                    <el-input
                        v-model="shippingDialog.formModel.expressName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    key="expressCode"
                    prop="expressCode"
                    label="快递公司代码"
                    label-width="8em"
                    v-if="shippingDialog.formModel.expressId === -1"
                >
                    <el-input
                        v-model="shippingDialog.formModel.expressCode"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    key="trackingNumber"
                    prop="trackingNumber"
                    label="快递单号"
                    label-width="8em"
                >
                    <el-input
                        v-model="shippingDialog.formModel.trackingNumber"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onShippingDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onShippingDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="取消订单"
            center
            width="600px"
            :visible.sync="cancelDialog.isVisible"
            @closed="onCancelDialogCancel"
        >
            <el-form
                ref="cancelForm"
                size="small"
                :model="cancelDialog.formModel"
                :rules="cancelDialog.formRules"
            >
                <!--                <el-form-item
prop="cancelDesc"
label="取消原因"
label-width="6em"
>
<el-input
type="textarea"
v-model="cancelDialog.formModel.cancelDesc"
auto-complete="off"
/>
</el-form-item>-->
                <el-form-item
                    prop="cancelDesc"
                    label="取消原因"
                    label-width="6em"
                >
                    <div>
                        <el-radio
                            class="ma-t ma-l"
                            v-model="cancelDialog.formModel.cancelDesc"
                            label="海关退单(身份证信息校验失败)"
                            border>海关退单(身份证信息校验失败)
                        </el-radio>
                        <el-radio
                            class="ma-t"
                            v-model="cancelDialog.formModel.cancelDesc"
                            label="海关退单(超个人年度购买限额)"
                            border>海关退单(超个人年度购买限额)
                        </el-radio>
                        <el-radio
                            class="ma-t"
                            v-model="cancelDialog.formModel.cancelDesc"
                            label="海关退单(购买人与支付人身份证信息不一致)"
                            border>海关退单(购买人与支付人身份证信息不一致)
                        </el-radio>
                        <el-radio
                            class="ma-t"
                            v-model="cancelDialog.formModel.cancelDesc"
                            label="海关退单(风控异常退单)"
                            border>海关退单(风控异常退单)
                        </el-radio>
                    </div>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onCancelDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onCancelDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Order',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                orderSn: '',
                deliveryConsignee: '',
                deliveryPhone: '',
                orderStatus: '20',
                goodsName: '',
                orderType: '',
                paymentMode: '',
                shipMethod: '1',
                buyerLevelId: '',
                pickupDate: '',
                extendFieldInfo: '',
                orderTime: '',
                goodsTypeNE: '',
                goodsType: '1',
                orderSource: '',
            },
            // 订单发货
            shippingDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    expressId: '',
                    expressName: '',
                    expressCode: '',
                    trackingNumber: '',
                },
                // 表单校验规则
                formRules: {
                    expressId: {
                        required: true,
                        message: '请选择快递公司',
                    },
                    expressName: {
                        required: true,
                        message: '请输入快递公司名称',
                        trigger: 'blur',
                    },
                    trackingNumber: {
                        required: true,
                        message: '请输入快递单号',
                        trigger: 'blur',
                    },
                },
                // 订单数据
                orderData: {},
            },
            // 取消订单
            cancelDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    cancelDesc: '',
                },
                // 表单校验规则
                formRules: {
                    cancelDesc: {
                        required: true,
                        message: '请输入取消原因',
                        trigger: 'blur',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            // 等级列表
            buyerLevelList: [],
            // 快递公司列表
            shopExpressList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.Order.orderList(queryData).then(json => {
                const res = json.data;
                res.data.forEach(item => {
                    const amountInfoList = [];
                    amountInfoList.push({
                        label: '商品总金额',
                        value: item.goodsAmount,
                    });
                    amountInfoList.push({
                        label: '运费',
                        value: item.freight,
                    });
                    amountInfoList.push({
                        label: this.$t('pageKey978', '包装费'),
                        value: item.packingAmount,
                    });
                    amountInfoList.push({
                        label: '优惠券金额',
                        value: item.couponAmount,
                    });
                    amountInfoList.push({
                        label: '积分抵扣金额',
                        value: item.pointAmount,
                    });
                    amountInfoList.push({
                        label: '满额减免金额',
                        value: item.reduceAmount,
                    });
                    amountInfoList.push({
                        label: '总优惠金额',
                        value: item.totalDiscountAmount,
                    });
                    amountInfoList.push({
                        label: '订单金额',
                        value: item.payableAmount,
                    });
                    item.amountInfoList = amountInfoList;
                });
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        // 订单发货
        shippingOrder(row) {
            this.shippingDialog.formModel.id = row.id;
            this.shippingDialog.orderData = row;
            this.shippingDialog.isVisible = true;
        },
        onShippingDialogConfirm() {
            const { shippingForm } = this.$refs;
            shippingForm.validate().then(() => {
                this.$api.Ex.Order.shippingOrder({
                    ...this.shippingDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.shippingDialog.isVisible = false;
                });
            });
        },
        onShippingDialogCancel() {
            const { shippingForm } = this.$refs;
            shippingForm.resetFields();
            const { formModel } = this.shippingDialog;
            formModel.id = '';
            this.shippingDialog.isVisible = false;
        },
        // 完成订单
        completeOrder(row) {
            this.$confirm('是否确定要完成该订单！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.Order.completeOrder({ orderId: row.id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        // 取消订单
        cancelOrder(row) {
            this.cancelDialog.formModel.id = row.id;
            this.cancelDialog.isVisible = true;
        },
        onCancelDialogConfirm() {
            const { cancelForm } = this.$refs;
            cancelForm.validate().then(() => {
                this.$api.Ex.Order.cancelOrder({
                    ...this.cancelDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.cancelDialog.isVisible = false;
                });
            });
        },
        onCancelDialogCancel() {
            const { cancelForm } = this.$refs;
            cancelForm.resetFields();
            const { formModel } = this.cancelDialog;
            formModel.id = '';
            this.cancelDialog.isVisible = false;
        },
        initLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
        },
        initShopExpressList() {
            this.$api.Sp.ShopExpress.data().then(json => {
                const res = json.data.data;
                res.push({
                    expressId: -1,
                    expressName: '其他快递公司',
                });
                this.shopExpressList = res;
            });
        },
        // excel导出
        exportOrderExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }
            let orderBeginTime = null;
            let orderEndTime = null;
            if (this.queryFormModel.orderTime != null && this.queryFormModel.orderTime.length === 2) {
                [orderBeginTime, orderEndTime] = this.queryFormModel.orderTime;
            }
            this.$http.href('/ex/order/exportOrderExcel', {
                ...this.queryFormModel,
                orderBeginTime,
                orderEndTime,
                ids,
            });
        },
        exportGoodsStatExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }
            let orderBeginTime = null;
            let orderEndTime = null;
            if (this.queryFormModel.orderTime != null && this.queryFormModel.orderTime.length === 2) {
                [orderBeginTime, orderEndTime] = this.queryFormModel.orderTime;
            }
            this.$http.href('/ex/order/exportGoodsStatExcel', {
                ...this.queryFormModel,
                orderBeginTime,
                orderEndTime,
                ids,
            });
        },
        // 批量发货
        onBatchShippingOrder() {
            this.batchShippingOrderDialog.isVisible = true;
        },
        onBatchShippingOrderDialogCancel() {
            this.batchShippingOrderDialog.isVisible = false;
        },
        onBatchShippingOrderSuccess(response) {
            if (response.success) {
                this.$message({
                    message: response.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
            } else {
                this.$alert(response.msg, '错误提示', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                });
            }
        },
        onBatchShippingOrderError(err) {
            this.$message({
                message: err,
                type: 'error',
            });
        },
        // 转仓库处理
        stockHandleOrder(ids) {
            this.$confirm('是否确定转仓库处理！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.Order.stockHandleOrder({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onBatchStockHandleOrder() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.stockHandleOrder(ids.join(','));
        },
    },
    created() {
        this.initLevelList();
        this.initShopExpressList();
    },
};
</script>

<style lang="scss">
</style>
